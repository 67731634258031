import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import algosdk from 'algosdk';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';

const algodClient = new algosdk.Algodv2('', process.env.NEXT_PUBLIC_ALGO_API_BASE_ENDPOINT, '');

const AlgoContext = createContext({});
export const useAlgo = () => useContext(AlgoContext);

export const ADDRESS_COOKIE_KEY = 'AlgoDaruma.address.0';

export const AlgoProvider = ({ children }) => {
  const router = useRouter();
  const [assets, setAssets] = useState([]);
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [isWinner, setIsWinner] = useState();

  const [hasOptedIn, setHasOptedIn] = useState(false);
  const [walletType, setWalletType] = useState('MyAlgo');
  const [theWallet, setTheWallet] = useState(null);

  const checkIfHasOptedIn = async (allTheAssets) => {
    const theTokenAsset = allTheAssets.find((asset) => asset['asset-id'] === parseInt(process.env.NEXT_PUBLIC_TOKEN_ID));

    if (theTokenAsset?.['asset-id'] === parseInt(process.env.NEXT_PUBLIC_TOKEN_ID)) {
      console.log('the token asset', theTokenAsset);
      setHasOptedIn(theTokenAsset);
    }
  };

  const disconnectWallet = async () => {
    try {
      await theWallet?.disconnect();
      console.log('disconnected');
    } catch (error) {
      console.error(error);
    }
    setAddress(null);
    Cookies.remove(ADDRESS_COOKIE_KEY);
    router.reload();
  };

  const getAssets = async () => {
    if (!address) {
      throw new Error('address is not defined');
    }
    const response = await axios.get(`/api/assets?address=${address}`);
    const them = response.data.filter((it) => it.amount);
    setAssets(them);
    checkIfHasOptedIn(them);
  };

  const optIn = async function (assetId) {
    const params = await algodClient.getTransactionParams().do();
    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: params,
        from: address,
        to: address,
        assetIndex: assetId,
        amount: 0,
      }),
    ];

    const signedTxn = await theWallet.signTransaction(
      walletType === 'MyAlgo' ? transactions.map((txn) => txn.toByte()) : [transactions.map((txn) => ({ txn: txn, signers: [address] }))]
    );
    let sendTransactions = [];
    for (const txn of signedTxn) {
      const sentTransaction = await algodClient.sendRawTransaction(walletType === 'MyAlgo' ? txn.blob : txn).do();
      sendTransactions.push(sentTransaction);
    }
    return sendTransactions;
  };

  const sendTokensToTokenWalletAndOptIn = async function (assetId, amount) {
    const params = await algodClient.getTransactionParams().do();
    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: params,
        from: address,
        to: process.env.NEXT_PUBLIC_TOKEN_WALLET,
        assetIndex: parseInt(process.env.NEXT_PUBLIC_TOKEN_ID),
        amount: amount,
      }),
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: params,
        from: address,
        to: address,
        assetIndex: assetId,
        amount: 0,
      }),
    ];

    const signedTxn = await theWallet.signTransaction(
      walletType === 'MyAlgo' ? transactions.map((txn) => txn.toByte()) : [transactions.map((txn) => ({ txn: txn, signers: [address] }))]
    );
    let sendTransactions = [];
    for (const txn of signedTxn) {
      const sentTransaction = await algodClient.sendRawTransaction(walletType === 'MyAlgo' ? txn.blob : txn).do();
      sendTransactions.push(sentTransaction);
    }
    await algosdk.waitForConfirmation(algodClient, sendTransactions[0].txId, 10);
    return sendTransactions;
  };

  useEffect(() => {
    if (!address) {
      return;
    }

    (async function () {
      try {
        setLoading(true);
        getAssets();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [address]);

  return (
    <AlgoContext.Provider
      value={{
        address,
        setAddress,
        walletType,
        setWalletType,
        setTheWallet,
        loading,
        assets,
        sendTokensToTokenWalletAndOptIn,
        optIn,
        hasOptedIn,
        disconnectWallet,
      }}
    >
      {children}
    </AlgoContext.Provider>
  );
};

import axios from 'axios';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { config } from '../config';
import { useAlgo } from './useAlgo';

const CollectionContext = createContext({});
export const useCollection = () => useContext(CollectionContext);

export const CollectionProvider = ({ children }) => {
  const { assets } = useAlgo();
  const [darumas, setDarumas] = useState([]);
  const [myDarumas, setMyDarumas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDarumas = async () => {
    setLoading(true);

    try {
      const { data } = await axios(`/api/collection?address=${process.env.NEXT_PUBLIC_V1_WALLET}`);
      const them = data.assets.filter((it) => it.index !== process.env.NEXT_PUBLIC_TOKEN_ID);
      setDarumas(them);

      const myAssetIds = assets.filter((asset) => asset.amount > 0).map((asset) => asset['asset-id']);
      const filteredAssets = them.filter((it) => myAssetIds.includes(it.index));
      setMyDarumas(filteredAssets);
    } catch (error) {
      console.error('error fetching darumas', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (assets.length === 0) return;
    fetchDarumas();
  }, [assets.length]);

  return (
    <CollectionContext.Provider
      value={{
        darumas,
        myDarumas,
        setMyDarumas,
        loading,
        error,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};

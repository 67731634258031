import axios from 'axios';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useAlgo } from './useAlgo';
import { useCollection } from './useCollection';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York');

const StakingContext = createContext({});
export const useStaking = () => useContext(StakingContext);

export const StakingProvider = ({ children }) => {
  const { address } = useAlgo();
  const { darumas, myDarumas } = useCollection();
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalStaked, setTotalStaked] = useState(0);
  const [stakedDarumas, setStakedDarumas] = useState([]);
  const [myEligibleDarumas, setMyEligibleDarumas] = useState([]);

  const [currentPeriod, setCurrentPeriod] = useState({
    start: '',
    end: '',
  });

  const getTotalStaked = async (address) => {
    try {
      const { data } = await axios.get(`/api/staking/total-staked`);
      setTotalStaked(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getStakedDarumas = async (showLoader) => {
    try {
      if (showLoader) {
        setLoading(true);
      }
      setFetched(false);
      const { data } = await axios.get(`/api/staking/stakers?address=${address}`);
      const them = darumas.filter((d) => data.darumas?.includes(d.index));
      setStakedDarumas(them);
      await getMyEligibleDarumas(them);
    } catch (error) {
      console.error(error);
    } finally {
      setFetched(true);
      setLoading(false);
    }
  };

  const getMyEligibleDarumas = async (staked) => {
    const myDarumasToStake = myDarumas.filter((d) => !staked.map((it) => it.index).includes(d.index));

    const them = await Promise.all(
      myDarumasToStake.map(async (d) => {
        const { data } = await axios.get(`/api/staking/eligible?address=${address}&d=${d.index}`);
        return { ...d, eligible: data };
      })
    );

    // filter out ineligible
    setMyEligibleDarumas(them.filter((daruma) => daruma.eligible));
  };

  const stakeDaruma = async (daruma, shouldStake) => {
    console.log('staking daruma');
    setLoading(true);

    try {
      await axios.post(`/api/staking/stake`, {
        address,
        daruma: daruma.index,
        shouldStake,
      });
      getStakedDarumas(false);
      if (shouldStake) {
        toast.success('Staked successfully!');
        setTotalStaked(totalStaked + 1);
      } else {
        toast.success('Unstaked successfully!');
        setTotalStaked(totalStaked - 1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const lastSunday = dayjs().day(0).startOf('day').format('MM/DD/YYYY');
    const nextSunday = dayjs().day(7).startOf('day').format('MM/DD/YYYY');

    setCurrentPeriod({
      start: lastSunday,
      end: nextSunday,
    });
  }, []);

  useEffect(() => {
    if (myDarumas.length === 0) {
      setLoading(false);
      setFetched(true);
    }
    if (!address || darumas.length === 0 || myDarumas.length === 0) {
      return;
    }

    getStakedDarumas(true);
  }, [address, darumas.length, myDarumas.length]);

  useEffect(() => {
    getTotalStaked();
  }, []);

  return (
    <StakingContext.Provider
      value={{
        stakedDarumas,
        stakeDaruma,
        myEligibleDarumas,
        totalStaked,
        currentPeriod,
        loading,
        fetched,
      }}
    >
      {children}
    </StakingContext.Provider>
  );
};

import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { onIdTokenChanged, User } from 'firebase/auth';
import nookies from 'nookies';

// lib
import { auth } from '../firebase-client';

const UserContext = createContext({});
const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState();

  const refreshUser = useCallback(async () => {
    setLoading(true);
    const user = await auth?.currentUser?.reload();
    setUser(user);
    setLoading(false);
  }, []);

  useEffect(() => {
    onIdTokenChanged(auth, async (user) => {
      if (!user) {
        setUser(null);
        nookies.set(undefined, 'token', '', { path: '/' });
      } else {
        const token = await user.getIdToken();
        setUser(user);
        nookies.set(undefined, 'token', token, { path: '/' });
      }
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        refreshUser,
        loading,
        success,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default useUser;

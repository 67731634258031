import { DefaultSeo } from 'next-seo';
import config from '../config';
import { ToastContainer } from 'react-toastify';

// components
import Header from '../components/Header';
import Footer from '../components/Footer';
import Providers from '../components/Providers';

// styles
import 'react-toastify/dist/ReactToastify.css';
import '../styles/globals.css';

function MyApp({ Component, pageProps }) {
  return (
    <>
      <DefaultSeo
        title={`Welcome to the ${config.siteName}`}
        description={config.description}
        openGraph={{
          type: 'website',
          locale: 'en_IE',
          url: config.domain,
          site_name: config.siteName,
          images: [
            {
              url: `${config.domain}/twitter-share-image.png`,
            },
          ],
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <Providers>
        <Header />
        <main>
          <Component {...pageProps} />
        </main>
        <Footer />
        <ToastContainer position='bottom-right' autoClose={5000} hideProgressBar={true} newestOnTop={false} closeOnClick draggable pauseOnHover />
      </Providers>
    </>
  );
}

export default MyApp;

const config = {
  siteName: 'AlgoDaruma',
  description:
    'AlgoDaruma is inspired generative digital art, created on the Algorand Blockchain. AlgoDaruma was inspired for the love of Japanese culture and art.',
  domain: 'https://www.algodaruma.com/',
  twitter: 'https://twitter.com/algodaruma',
  instagram: 'https://www.instagram.com/algodaruma/',
  discord: 'https://discord.com/invite/JmR4cxRSw2',
  nftExplorer: 'https://www.nftexplorer.app/collection/algo-daruma',
  nftExplorerSellers: 'https://www.nftexplorer.app/sellers/algo-daruma',
  algoxnft: 'https://algoxnft.com/collection/algo-daruma',
  contactEmail: 'admin@algodaruma.com',
  team: [
    {
      name: 'Jugo V.',
      role: 'Founder & Illustrator',
      image: '/team/jugo.jpeg',
      twitter: '#',
    },
    {
      name: 'Yuki C.',
      image: '/team/yuki.jpeg',
      role: 'Social Media Manger',
      twitter: '#',
    },
    {
      name: 'Ezi G.',
      image: '/team/ezi.jpeg',
      role: 'Creative Designer',
      twitter: '#',
    },
    {
      name: 'Jet E.',
      image: '/team/jet.jpeg',
      role: 'Creative Designer',
      twitter: '#',
    },
  ],
  roadmap: {
    q1: [
      {
        label: 'Customs for holders',
        done: false,
      },
      {
        label: 'New game add-ons',
        done: false,
      },
      {
        label: 'Double KARMA Friday',
        done: true,
      },
      {
        label: 'Yokai Body',
        done: true,
      },
      {
        label: 'Yokai Faces',
        done: false,
      },
      {
        label: 'Yokai Weapons',
        done: true,
      },
      {
        label: 'Yokai Costumes',
        done: false,
      },
      {
        label: 'Yokai Backgrounds',
        done: false,
      },
      {
        label: 'Physical Items and Exchange System',
        done: false,
      },
    ],
    // q2: [],
  },
};

export default config;

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

// hooks
import { useAlgo } from './useAlgo';

const RafflesContext = createContext({});
export const useRaffles = () => useContext(RafflesContext);

export const RafflesProvider = ({ children }) => {
  const [balance, setBalance] = useState(0);
  const { hasOptedIn, loading } = useAlgo();

  const joinRaffle = async (txn, id, address, tickets) => {
    const { data } = await axios.post(
      `/api/raffles/buy`,
      { txId: txn[0].txId, raffleId: id, address, tickets: parseInt(tickets) },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: process.env.NEXT_PUBLIC_API_KEY,
        },
      }
    );
    console.log('joined raffle', data);
  };

  const getEntries = async (id) => {
    const { data } = await axios.get(`/api/raffles/entries?id=${id}`, {
      headers: {
        Authorization: process.env.NEXT_PUBLIC_API_KEY,
      },
    });

    // sort by transaction.['round-time']
    return [...data].sort((a, b) => {
      const aTime = a.transaction['round-time'];
      const bTime = b.transaction['round-time'];
      return bTime - aTime;
    });
  };

  useEffect(() => {
    // * set initial balance
    if (hasOptedIn) {
      setBalance(hasOptedIn?.amount);
    }
  }, [hasOptedIn]);

  return (
    <RafflesContext.Provider
      value={{
        balance,
        setBalance,
        loading,
        joinRaffle,
        getEntries,
      }}
    >
      {children}
    </RafflesContext.Provider>
  );
};
